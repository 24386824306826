import { createApp } from 'vue';
import router from '@/router';
import App from '@/App';
import { Formatters, Toaster, Tooltip } from '@/commons/plugins';
import VueComponents from '@transfeeradev/vue-components';
import { Bridge, DialogStack, DialogStackIK } from '@transfeeradev/bridge';
import PDFObjectPlugin from 'pdfobject-vue';
import config from '@/config';

import '@/less/main.less';
import '@/svgLoader';

function init() {
  // set Theme brand in <head>
  let link = document.querySelector("link[rel*='icon']");
  link.href = `${config.branding.assets}/img/favicon.ico`;
  document.title = config.branding.name;

  // set theme class
  document.body.classList.add(`theme-${config.branding.id}`);
}

init();

const app = createApp(App);

app.use(router);
app.use(Toaster);
app.use(Tooltip);
app.use(Formatters);
app.use(DialogStack, { router });
app.use(PDFObjectPlugin);
app.use(Bridge);

app.use(VueComponents, {
  dialogStack: {
    injectionKey: DialogStackIK
  }
});

app.mount('#app');
