import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.payin + '/app';

export const payinsResource = {
  /**
   * @returns {Promise<{
   * items: Payin[],
   * metadata: {pagination: {itemsPerPage: number, totalItems: number}}}>}
   */
  get: ({ page = 0, page_size = 10, status__in, payment_type__in, search, created_at__gte, created_at__lte }) => {
    const query = new URLSearchParams({ page, page_size });

    for (const [key, value] of Object.entries({
      search,
      created_at__gte,
      created_at__lte
    })) {
      if (value) {
        query.append(key, value);
      }
    }

    if (status__in) {
      for (let filterStatus of status__in) {
        filterStatus === 'canceled'
          ? (query.append('status__in', 'canceled_by_user'), query.append('status__in', 'canceled_by_psp'))
          : query.append('status__in', filterStatus);

        filterStatus === 'refunded'
          ? query.append('status__in', 'partially_refunded')
          : query.append('status__in', filterStatus);
      }
    }

    if (payment_type__in) {
      for (let filterPaymentType of payment_type__in) {
        query.append('payment_type__in', filterPaymentType);
      }
    }

    return fetch(`${BASE_URL}/payins?${query}`);
  },
  getById: id => {
    return fetch(`${BASE_URL}/payins/${id}`);
  },

  /** @returns {string} id */
  exportCSV: ({ created_at__gte, created_at__lte, search, status__in, payment_type__in } = {}) => {
    return fetch(`${BASE_URL}/payin_reports`, {
      method: 'POST',
      body: JSON.stringify({
        created_at__gte,
        created_at__lte,
        search,
        status__in,
        payment_type__in
      })
    });
  },

  /** @returns {Promise<{items: PayinReport[]}>} */
  getReportById: id => {
    return fetch(`${BASE_URL}/payin_reports/${id}`);
  }
};
