import { payinStatus } from '@/apps/payin/payins/constants/payinStatus';

const payinReceiptButtonStatus = {
  received: 'Comprovante de recebimento',
  notReceivedYet: 'Cobrança ainda não foi recebida',
  notReceivedWithPix: 'Cobrança não foi recebida com Pix'
};

export const receiptButtonStatus = payin => {
  if (isReceivedAndReceiptAvailable(payin)) {
    return payinReceiptButtonStatus.received;
  }
  if (payin.status === payinStatus.PENDING) {
    return payinReceiptButtonStatus.notReceivedYet;
  }

  return payinReceiptButtonStatus.notReceivedWithPix;
};

export const isReceivedAndReceiptAvailable = payin => {
  return (
    (payin.status === payinStatus.RECEIVED ||
      payin.status === payinStatus.REFUNDED ||
      payin.status === payinStatus.PARTIALLY_REFUNDED ||
      payin.status === payinStatus.REFUNDING) &&
    payin.receipt_file_url
  );
};
