<template>
  <!-- // https://skeletonreact.com/ -->
  <svg
    role="img"
    aria-labelledby="loading-aria"
    viewBox="0 0 312 174"
    preserveAspectRatio="none"
  >
    <title id="loading-aria">Loading...</title>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      clip-path="url(#clip-path)"
      style="fill: url(&quot;#fill&quot;);"
    />
    <defs>
      <clipPath id="clip-path">
        <rect
          x="104"
          y="53"
          rx="3"
          ry="3"
          width="139"
          height="10"
        />
        <rect
          x="59"
          y="127"
          rx="3"
          ry="3"
          width="199"
          height="15"
        />
        <rect
          x="101"
          y="12"
          rx="3"
          ry="3"
          width="201"
          height="10"
        />
        <rect
          x="557"
          y="244"
          rx="3"
          ry="3"
          width="120"
          height="6"
        />
        <rect
          x="242"
          y="125"
          rx="0"
          ry="0"
          width="2"
          height="0"
        />
        <rect
          x="249"
          y="130"
          rx="0"
          ry="0"
          width="1"
          height="0"
        />
        <rect
          x="5"
          y="7"
          rx="17"
          ry="17"
          width="86"
          height="82"
        />
      </clipPath>
      <linearGradient id="fill">
        <stop
          offset="0.599964"
          stop-color="#f3f3f3"
          stop-opacity="1"
        >
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop
          offset="1.59996"
          stop-color="#ecebeb"
          stop-opacity="1"
        >
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop
          offset="2.59996"
          stop-color="#f3f3f3"
          stop-opacity="1"
        >
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
</template>
