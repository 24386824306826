<template>
  <div
    class="summary-cards"
    role="list"
  >
    <slot />
  </div>
</template>

<script setup>

</script>

<style scoped>
.summary-cards {
  display: flex;
  flex-direction: row;
  gap: var(--bg-spacing-sm);
  isolation: isolate;
}
</style>
