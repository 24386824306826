//@ts-check
import loggedUserResource from '@/commons/resources/loggedUser';

/** @typedef {{ [key: string]: string } } Flag */

/**
 * @param {string} key
 * @param {string} [variant]
 */
export function hasFeatureFlag(key, variant) {
  /** @type { Flag } */
  const flags = loggedUserResource.getFeatureFlags();

  if (variant) {
    return flags[key] === variant;
  }

  return !!flags[key];
}
