<template>
  <tr-dialog
    ref="modal"
    name="entry-wizard-dialog"
    :hide-cancel-button="true"
    :before-close="beforeClose"
    :has-its-own-buttons="true"
    title="Enviar saldo"
  >
    <entry-wizard-container
      :close="close"
      :on-change="onChange"
    />
  </tr-dialog>
</template>

<script>
import EntryWizardContainer from '@/apps/payments/payments/statement/components/container/EntryWizardContainer.vue';
import { APPS } from '@/commons/constants/apps';
import { authAs, getCurrentApp } from '@/commons/services/apps';

export default {
  name: 'entry-wizard-dialog',
  components: {
    EntryWizardContainer
  },
  props: {
    onChange: {
      required: false,
      type: Function
    }
  },
  methods: {
    async open() {
      this.currentApp = getCurrentApp()?.name || APPS.PAYMENTS.key;

      await authAs(APPS.PAYMENTS.key);

      this.$refs.modal.open();

      document.body.classList.add('dialog-modal--open');
    },
    async beforeClose() {
      document.body.classList.remove('dialog-modal--open');
      await authAs(this.currentApp);
    },
    async close() {
      this.$refs.modal.close();
    }
  }
};
</script>

<style>
.dialog-modal--open {
  .sidebar.app-sidebar {
    z-index: 0;
  }
}
</style>
