<template>
  <tr-form :on-valid-submit="onValidSubmit">
    <first-steps-explanation />
    <tr-show-for-roles
      v-if="isFirstTimeFilling"
      :roles="rolesToHaveAllActionsShown"
    >
      <div class="row vertical-offset4">
        <div class="center-h-and-v col-lg-3">
          <tr-avatar
            :is-outlined="true"
            size="semi-large"
            text-color="blue"
            color="blue"
            text="1"
          />
        </div>
        <tr-page-section
          classes="col-lg-9"
          title="Em qual conta você irá receber seus saques"
          description="Precisamos dos dados de conta corrente da sua empresa para realizarmos os saques e devoluções. Lembre-se que você está em ambiente seguro e criptografado, suas informações estão bem guardadas!"
          :no-margin="true"
        >
          <company-bank-account-form-container
            ref="bankAccountForm"
            hide-buttons="true"
          />
        </tr-page-section>
      </div>
    </tr-show-for-roles>
    <div class="row vertical-offset4">
      <div
        v-if="shouldShowSomeActionsForTheUser"
        class="center-h-and-v col-lg-3"
      >
        <tr-avatar
          :is-outlined="true"
          size="semi-large"
          text-color="blue"
          color="blue"
          text="2"
        />
      </div>
      <div class="col-lg-9">
        <div class="security-container__title">
          <bg-text type="h5">
            Autenticação em 2 fatores
          </bg-text>
          <bg-badge
            v-if="hasTwoFactorEnabled"
            color="green-light"
            size="sm"
            class="security-container__badge"
          >
            <bg-text color="green-semi-dark">
              Ativada
            </bg-text>
          </bg-badge>
        </div>
        <bg-stack
          v-if="!hasTwoFactorEnabled"
          space="md"
        >
          <bg-text type="body-md">
            Ativando a autenticação em 2 fatores, sempre que você fizer seu login na Transfeera,
            será solicitado um novo código, que só você terá em um aplicativo no seu smartphone. <bg-text
              is="strong"
              type="body-md"
              weight="bold"
            >
              Ative agora e fique ainda mais protegido!
            </bg-text>
          </bg-text>
          <footer>
            <tfa-activation-button @activate="hasTwoFactorEnabled = true" />
          </footer>
        </bg-stack>
      </div>
    </div>
    <tr-show-for-roles
      v-if="isFirstTimeFilling"
      :roles="rolesToHaveAllActionsShown"
    >
      <div class="row vertical-offset4">
        <div class="center-h-and-v col-lg-3">
          <tr-avatar
            :is-outlined="true"
            size="semi-large"
            text-color="blue"
            color="blue"
            text="3"
          />
        </div>
        <tr-page-section
          classes="col-lg-9"
          title="Correção Automática"
          :no-margin="true"
        >
          <template #description>
            <tr-typography>
              Através de validações na Receita Federal e nos bancos, conseguimos
              <tr-typography strong="true">
                corrigir dados bancários
              </tr-typography> incorretos (Dígito da agência ou
              conta, tipo da conta, abreviaturas de nome, etc), mas sempre
              <tr-typography strong="true">
                garantindo que pagaremos para o CPF ou CNPJ correto, na Conta Certa!
              </tr-typography>
            </tr-typography>
          </template>
          <conta-certa-config-container />
        </tr-page-section>
      </div>
    </tr-show-for-roles>
    <page-footer>
      <template #right>
        <tr-button
          type="submit"
          :disabled="isSaving"
          label="Finalizar cadastro"
          variant="raised"
          color="primary"
        />
      </template>
    </page-footer>
  </tr-form>
</template>

<script>
import CompanyBankAccountFormContainer from '@/apps/payments/payments/settings/bankAccounts/components/container/CompanyBankAccountFormContainer';
import ContaCertaConfigContainer from '@/apps/payments/payments/settings/config/components/container/ContaCertaConfigContainer';
import FirstStepsExplanation from '@/apps/payments/payments/config/components/presentational/FirstStepsExplanation';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import { roles } from '@transfeeradev/api-enums';
import PageFooter from '@/commons/components/presentational/PageFooter.vue';
import settingsResource from '@/commons/resources/settings';
import { BgText, BgBadge, BgStack } from '@transfeeradev/bridge';
import TfaActivationButton from '@/apps/my-account/settings/components/TfaActivationButton.vue';

export default {
  name: 'first-steps-page',
  components: {
    CompanyBankAccountFormContainer,
    ContaCertaConfigContainer,
    TfaActivationButton,
    FirstStepsExplanation,
    PageFooter,
    BgText,
    BgBadge,
    BgStack
  },
  props: {
    onSave: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    rolesToHaveAllActionsShown: roles.appRoles.ADMIN,
    isFirstTimeFilling: false,
    isSaving: false,
    hasTwoFactorEnabled: false
  }),
  computed: {
    shouldShowSomeActionsForTheUser() {
      return verifyRoleAccess.isLoggedUserAllowedToAccess(this.rolesToHaveAllActionsShown) && this.isFirstTimeFilling;
    }
  },
  created() {
    settingsResource.get().then(data => {
      const { hasTwoFactorEnabled: has2FA } = data;
      this.hasTwoFactorEnabled = has2FA;
    });
    return this.refresh();
  },
  methods: {
    refresh() {
      return checkpointResource.existsInCompany(checkpoint.HAS_FILLED_FIRST_STEPS).then(result => {
        this.isFirstTimeFilling = !result.exists;
      });
    },
    onValidSubmit() {
      if (!this.shouldShowSomeActionsForTheUser) {
        this.isSaving = true;
        return this.onSuccessSave();
      }

      const bankAccountForm = this.$refs.bankAccountForm;

      this.isSaving = true;
      return bankAccountForm.onSubmit().then(
        () => {
          this.$toaster.success('Dados salvos com sucesso');
          return this.onSuccessSave();
        },
        () => {
          this.isSaving = false;
        }
      );
    },
    async onSuccessSave() {
      await checkpointResource.create(checkpoint.HAS_FILLED_FIRST_STEPS);
      const redirectedFrom = history.state?.redirectedFrom;

      if (redirectedFrom) {
        this.$router.push({ name: redirectedFrom });
      } else if (this.onSave) {
        this.onSave();
      }
    }
  }
};
</script>

<style scoped>
.security-container__title {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.security-container__badge {
  height: 24px;
}
</style>
